@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Define your font family
@font-family: 'League Spartan', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif;

// Component specific fonts
@btn-font-family: @font-family;
@input-font-family: @font-family;

// Your existing color variables
@primary-color: #580c8b;
@link-color: #580c8b;
@success-color: #52c41a;
@warning-color: #faad14;
@error-color: #f5222d;
@font-size-base: 14px;
@heading-color: rgba(0, 0, 0, 0.85);
@text-color: rgba(0, 0, 0, 0.65);
@text-color-secondary: rgba(0, 0, 0, 0.45);
@disabled-color: rgba(0, 0, 0, 0.25);
@border-radius-base: 4px;
@border-color-base: #d9d9d9;
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); 